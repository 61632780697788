import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PopupData {
  type: string;
} 

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  type: String;
  title: String;

  constructor(public dialogRef: MatDialogRef<PopupComponent>, @Inject(MAT_DIALOG_DATA) public data: PopupData) {
  }

  ngOnInit() {
    if(this.data.type == "paypal") {
      this.type = "paypal"
      this.title = "Pay with PayPal"
    } else if(this.data.type == "check") {
      this.type = "check"
      this.title = "Pay by check"
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ChargeResponse } from '../model/ChargeResponse';
import { OpenCharge } from '../model/OpenCharge';
import { ChargeService } from '../services/chargeService';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-account-activity',
  templateUrl: './account-activity.component.html',
  styleUrls: ['./account-activity.component.scss'],
  host: {
    class: 'main_content'
  }
})
export class AccountActivityComponent implements OnInit {
  @Input() isNested: boolean;
  @Input() chargeResponse: ChargeResponse;
  charges: OpenCharge[];
  currentBalance: String;
  amountHeader: String;
  hasRecoveryAmount: Boolean;

  constructor(private chargeService: ChargeService, private dialog: MatDialog) {

  }

  ngOnInit() {

    if (this.isNested != true) {
      console.log("Page requesting charges")
      this.chargeService.getCharges('').subscribe(result => {
        this.chargeResponse = result;
        if(result.charges && result.charges.length > 0) {
          this.configureTableData()
        }
      });
    }
  }



  ngOnChanges() {
    this.configureTableData()
  }

  configureTableData() { console.log("configure Table Data")
    this.charges = []

    this.charges = this.chargeResponse.charges
    this.amountHeader = this.chargeResponse.anyHEC ? "Gross Amount" : "Amount";
    this.currentBalance = Number(this.chargeResponse.currentBalance).toLocaleString(undefined, { style: 'currency', currency: this.chargeResponse.balanceCurrency, minimumFractionDigits: 2 }) + " " + this.chargeResponse.balanceCurrency;

    if (!this.chargeResponse.formatted) { //Skip formatting if cached and already formatted      
      for (var charge of this.charges) {
        charge.grossAmount = charge.grossAmount ? Number(charge.grossAmount).toLocaleString(undefined, { style: 'currency', currency: charge.currency, minimumFractionDigits: 2 }) + " " + charge.currency : "N/A"

        if (charge.recoveryAmount != null && charge.recoveryAmount.length > 0) {
          this.chargeResponse.hasRecoveryAmount = true;
          charge.recoveryAmount = Number(charge.recoveryAmount).toLocaleString(undefined, { style: 'currency', currency: charge.currency, minimumFractionDigits: 2 }) + " " + charge.currency
        } else {
          charge.recoveryAmount = "N/A"
        }
      }
    }

    this.hasRecoveryAmount = this.chargeResponse.hasRecoveryAmount;

    this.chargeResponse.formatted = true;
  }

  openPopup(input: string) {
    console.log("Pop up")
    let h: string;
    let w: string;
    if (input == 'paypal') {
      h = '670px'; w = '600px'
    } else if (input == 'check') {
      h = '300px'; w = '500px'
    }
    const dialogRef = this.dialog.open(PopupComponent, {
      height: h,
      width: w,
      data: {type: input}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

    // dialogRef.close();
  }


isOverpaymentValid(overpaymentDate: Date): boolean {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); 

  const overpaymentDateObject = new Date(overpaymentDate);

  // Creating a new Date object for March 1st
  let firstDayOfMarch: Date;
  if (overpaymentDateObject.getFullYear() === currentYear) {
      firstDayOfMarch = new Date(currentYear + 1, 2, 1); 
  } else {
      firstDayOfMarch = new Date(currentYear, 2, 1); 
  }

  const isValid = overpaymentDateObject < firstDayOfMarch;

  // Combining both conditions to determine overall validity
  const isCurrentYear = overpaymentDateObject.getFullYear() === currentYear;
  const isValidOverall = isValid && isCurrentYear;

  return isValidOverall;
}

}
// trigger build
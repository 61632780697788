import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Results, Resultscontent } from '../model/resultscontent';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TBSService } from '../services/tbs.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  displayedColumns: string[] = ['invoiceNumber', 'salesOrderNumber', 'chargeCategory', 'chargeCode', 'totalNetAmount'];
  displayedColumns1: string[] = ['invoiceNumber', 'chargeCategory', 'chargeCode', 'totalNetAmount', 'errors'];
  ELEMENT_DATA: Resultscontent[] = [];
  dataSourceSuccess : MatTableDataSource<Resultscontent>;
  dataSourceFailure : MatTableDataSource<Resultscontent>;
  @ViewChild(MatPaginator, {static: true}) paginatorS: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) paginatorF: MatPaginator;
  results: Results;
  @Input() fileID : string;

  totalSizeS = 0;
  pageIndexS = 0;
  pageSizeS = 5

  
  totalSizeF = 0;
  pageIndexF = 0;
  pageSizeF = 5

  constructor(private tbsService: TBSService) {}
  ngOnInit() {

    this.retrieveInvoiceSuccess();
    this.retrieveInvoiceFailure();
  }

  retrieveInvoiceSuccess(event?: PageEvent){


    console.log('calling retrieve invoice Results');

    if (event) {
      console.log(event)
      this.pageIndexS = event.pageIndex;
      this.pageSizeS = event.pageSize;
    }

     this.tbsService.retrieveInvoiceResultsSuccess(this.fileID, this.pageSizeS, this.pageIndexS).subscribe(invoiceResults => {
      this.pageSizeS = invoiceResults.size;
      this.pageIndexS = invoiceResults.number;
      this.totalSizeS = invoiceResults.totalElements;
      
      this.dataSourceSuccess = new MatTableDataSource<Resultscontent>(invoiceResults.content);

      console.log('success count' + invoiceResults.content.length);
      
    }); 

  }

  public currencyFormat(value: string): string {
    return Number(value).toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
  }


  retrieveInvoiceFailure(event? : PageEvent){

    console.log('calling retrieve invoice Results');
    if (event) {
      console.log(event)
      this.pageIndexF = event.pageIndex;
      this.pageSizeF = event.pageSize;
    }
    this.tbsService.retrieveInvoiceResultsFailure(this.fileID, this.pageSizeF, this.pageIndexF).subscribe(invoiceResults => {
      this.pageSizeF = invoiceResults.size;
      this.pageIndexF = invoiceResults.number;
      this.totalSizeF = invoiceResults.totalElements;
     this.dataSourceFailure = new MatTableDataSource<Resultscontent>(invoiceResults.content);

     console.log('failure count' + invoiceResults.content.length);
     
   });

  }

}


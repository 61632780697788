<div class="popup-container">
  <div class="popup">
    <div class="popup-icon">
      <img src="assets/img/check-icon.png" alt="Success Icon" />
    </div>
    <h1 mat-dialog-title>Thank you! Your payment has been successfully processed.</h1>
    <div mat-dialog-content>
      <p>Please note that it can take up to 24 hours for your payment to post and clear your account.</p>
    </div>
    <div mat-dialog-actions class="centre-actions">
      <button mat-button mat-dialog-close class="ok_button" (click)="refreshPage()">OK</button>
    </div>
  </div>
</div>

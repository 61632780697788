<div mat-dialog-content>
    <h1 class="title">{{title}}</h1>

    <div *ngIf="type == 'paypal'">
        <h4 class="info">
            You do not need a PayPal account to pay via PayPal. For this method you can use a credit card, debit card,
            or
            PayPal account.
        </h4>
        <h4 class="info">
            Once your payment is made through PayPal it will take up to 3 business days to reflect on your account.
        </h4>
        
        <h4 class="info">Please visit <a href="http://www.employeepay.aa.com" target="_blank">www.employeepay.aa.com</a></h4>
        <h4 class="info">
            Enter your 6 digit AA ID# in the ‘Description’ box and the amount you are paying in the ‘Price per item’
            box.
        </h4>
        <h4 class="example">Example:</h4>
        <img src="assets/images/instructions2.png" alt="PayPal instructions" width="507" height="375" class="centered">
    </div>

    <div *ngIf="type == 'check'">
        <h4 class="info">
            Please make payable to American Airlines and include your employee number on the memo line.
        </h4>
        <h4 class="info">
            <span style="font-weight: bold;">Mail to:</span><br>
            American Airlines, Inc.<br>
            Employee Receivables<br>
            P.O. Box 73928<br>
            Chicago, IL 60673
        </h4>
    </div>
</div>




<div mat-dialog-actions>
    <button class=okButton (click)="dismiss()">Close</button>
</div>
import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { ChargeService } from '../services/chargeService';
import { Customer } from '../model/Customer';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit {

  user: Customer;

  currentBalance: string;

  name: string;
  employeeId: string;
  address: string;
  primaryEmail: string;
  secondaryEmail: string;
  phone: string;

  constructor(private userService: UserService, private authService: AuthService, private chargeService: ChargeService) {
    // this.user = {name: 'TestUser', address: "4255 Amon Carter Blvd, Fort Worth, TX 76155", employeeId: '0010256', primaryEmail: 'test.user1@aa.com', secondarEmail: 'test.user2@aa.com', phone: '480-693-8120'}
  }

  ngOnInit() {
    this.userService.getUserInfo('mck').subscribe(result => {
      console.log('Result: ' + result);
      this.user = result
      // let centralData = result.MT_RetrieveCustomer_Res.Customer_List.Header_Data[0].Central_Data
      // this.name = this.prettyCase(centralData.name1)
      // this.employeeId = result.MT_RetrieveCustomer_Res.Customer_List.Header_Data[0].customerNumber
      // this.address = this.prettyCase(centralData.street + centralData.street2 + centralData.street3 + ", " + centralData.city + ", " + centralData.region + " " + centralData.postalCode)
      // this.primaryEmail = centralData.email[0].emailAddress.toLowerCase()
      // this.secondaryEmail = centralData.email[1].emailAddress.toLowerCase()
      // this.phone = centralData.phone[0].telephoneNumber
      // this.getUserInfo()
    });
    this.chargeService.getCharges('').subscribe(result => {
      this.currentBalance = result.currentBalance
    });
  }

  // public getUserInfo() {
  //   console.log("Get user name")
  //   this.authService.isAuthenticated()
  //     .subscribe(user => {
  //       this.user.name = user.name.replace(",", " ")
  //       this.user.employeeId = user.userAuthentication.details.locale
  //       this.user.primaryEmail = user.userAuthentication.details.email
  //       this.user.secondaryEmail = user.userAuthentication.details.email
  //     });
  // }

  // addressFormat(info: CentralData): string {

  //   return ""
  // }

  prettyCase(input: String): string {
    return input.toLowerCase().replace(/\b[a-z]/g, c => c.toUpperCase())
  }


}


import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { StatusResponse } from '../model/StatusResponse';
import { Subscription, interval } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UploadResponse } from '../model/upload-response';
import { UploadDetail } from '../model/UploadDetail';
import { MatTableDataSource } from '@angular/material/table';
import { TBSService } from '../services/tbs.service';
import { Results } from '../model/resultscontent';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-tbs-upload',
  templateUrl: './tbs-upload.component.html',
  styleUrls: ['./tbs-upload.component.scss']
})
export class TbsUploadComponent implements AfterViewInit {

  fileID: string;
  filename: string;
  fileInputEvent: any;
  showDelete = false;
  response:Response;
  statusResponse : StatusResponse;
  uploadResponse: UploadResponse;
  filedata: string;
  mdPollInterval: Subscription;
  uploadPollInterval: Subscription;
  uploadsInProgress: UploadDetail[] = [];
  isPolling: boolean;
  @ViewChild('xmlInput', {static: false}) inputFileid: ElementRef;
  displayedColumns: string[] = ['filename', 'timestamp', 'userId', 'invoiceCount', 'success', 'failure'];
  fileSource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  disableSubmit = false;
  selectedFileName: string;
  dataSource: MatTableDataSource<UploadDetail>;

  totalSize = 0;
  pageIndex = 0;
  pageSize = 5

  results: Results;

  constructor(private tbsService: TBSService, private _snackbar: MatSnackBar) {
    
    console.log('calling retrieve upload details');
    this.retrieveResults(null)
    this.startUploadPolling();
    this.statusUpdate();
  }

  ngAfterViewInit() {
    // this.statusUpdate();
    
  }

  ngOnIt(){
    // this.statusUpdate();
    
  }


  getfileID(id: number, fileName: string){
    console.log(id);
    this.fileID = id.toString();
    this.selectedFileName = ' for ' + fileName;
  }



  retrieveResults(event?: PageEvent) {

    if (event) {
      console.log(event)
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }

    this.tbsService.retrieveUploadDetails(this.pageSize, this.pageIndex).subscribe(uploadDetailResult => {
      this.pageSize = uploadDetailResult.size;
      this.pageIndex = uploadDetailResult.number;
      this.totalSize = uploadDetailResult.totalElements;
      this.dataSource = new MatTableDataSource<UploadDetail>(uploadDetailResult.content);
      console.log('Updated data source')
    });
  }


  startUploadPolling() {
    console.log('Start upload polling')
    this.uploadPollInterval = interval(6000).subscribe(() => {
      this.checkUploadStatus();
    })
  }

  checkUploadStatus() {
    console.log('Will check upload status.  In progress: '+this.uploadsInProgress.toString())

    this.tbsService.uploadStatus().subscribe(results => {
      
      let completed: String[] = [];
      this.uploadsInProgress.forEach(current => {
        const contains = this.contains(current, results);
        if(!contains) {
          completed.push(current.filename);
        }
      });

      console.log('Completed: '+completed.toString())
      if(completed.length > 0) {
        let msg = 'File(s) finished uploading: ' + completed.join(', ');
        if(this.pageIndex == 0) {
          this.retrieveResults(null);
        }
        this.openSnackBar(msg);
      }

      this.uploadsInProgress = results;
      //if empty end polling
      if(this.uploadsInProgress.length <= 0) {
        console.log('No more uploads in progress')
        this.uploadPollInterval.unsubscribe();
      }
    });
  }

  contains(element: UploadDetail, list: UploadDetail[]): boolean {
    for (let i = 0; i < list.length; i++) {
      const listElm = list[i];
      if (element.id == listElm.id) {
        return true;
      }
    }
    return false;
  }

  openSnackBar(msg: string) {
    this._snackbar.open(msg, 'OK', {
      duration: 8000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'upload-notif'
    });
  }
  
  xmlInputChange(fileInputEvent: any) {
    const file = fileInputEvent.target.files[0];
    this.fileSource = file;
    this.filename = file.name;
    if(this.filename){
      this.showDelete = true;
      const reader = new FileReader();
      reader.onload = (e: any) => {
      } 
      reader.readAsText(file);
    }
    else {
      this.showDelete = false;
    }
  }



  //BUTTONS

  onSubmit(){
    
    this.tbsService.uploadXML(this.fileSource, this.filename).subscribe(result => {
      this.uploadsInProgress.push(result);
      if (this.pageIndex == 0) {
        this.retrieveResults(null);
      }
      if (this.uploadPollInterval.closed) {
        console.log('sub is closed')
        this.startUploadPolling();
      }
      
      this.openSnackBar("Mass upload received. Results will be displayed in the table after the the file finishes processing.");
      this.deleteFile();
      console.log(result);
    },
    err => {
      console.log(err)
      this.openSnackBar("Could not process file. Please check file contents and try again. If problems persist, please contact IT.");
    });

  }

  deleteFile() {
    this.inputFileid.nativeElement.value = null;
    this.filename = "";
    this.showDelete = false;
  }


  goBack(){
    this.fileID = null;
    this.selectedFileName = '';
  }



  //MASTER DATA

  refreshCostCenter(){
    this.statusResponse.costCenter.refreshing = true;
    this.tbsService.refreshCostCenter().subscribe(result => {
      console.log(result)
      if (result == "Completed") {
        this.statusUpdate();
      } else {
        if (result == "Already Started") {
          this.openSnackBar("Refresh already in progress!");
        }
        this.startMDPolling();
      }
    });
  }

  refreshMaterialAccount(){
    this.statusResponse.materialAccount.refreshing = true;
    this.tbsService.refreshMaterial().subscribe(result => {
      console.log(result);
      if (result == "Completed") {
        this.statusUpdate();
      } else {
        if (result == "Already Started") {
          debugger;
          this.openSnackBar("Refresh already in progress!");
        }
        this.startMDPolling();
      }
    });
  }

  refreshFSG(){
    this.statusResponse.fsg.refreshing = true;
    this.tbsService.refreshFSG().subscribe(result => {
      console.log(result);
      if (result == "Completed") {
        this.statusUpdate();
      } else {
        if (result == "Refresh started.  Will finish in background") {
          this.openSnackBar("Refresh already in progress!");
        }
        this.startMDPolling();
      }
    });
  }


  startMDPolling() {
    this.mdPollInterval = interval(6000).subscribe(() => {
      this.statusUpdate();
    })
  }
  
  statusUpdate(){
    this.tbsService.checkRefreshStatus().subscribe(result => {
      console.log(result);
      this.statusResponse = result;
      if (!(this.statusResponse.costCenter.refreshing || this.statusResponse.materialAccount.refreshing || this.statusResponse.fsg.refreshing) && this.mdPollInterval) {
        this.mdPollInterval.unsubscribe();
      }
    });
  }


}
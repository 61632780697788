<div class="portal_menu" id="menu">

    <h3 class="heading">{{name}}</h3>
    <h4 class="subtitle">{{employeeId}}</h4>

    <p class="hist_link" *ngIf="isEligible"><a href="#/activity">Account activity</a></p>
    <p class="hist_link" *ngIf="isEligible"><a href="#/info">My contact information</a></p>
    <p class="hist_link" *ngIf="isAdmin"><a href="#/admin">Admin</a></p>
    <p class="hist_link" *ngIf="isTBSAdmin"><a href="#/tbsupload">TBS upload</a></p>
    <p class="hist_link" *ngIf="isEligible"><a href="#/help">Help</a></p>
    
    <!-- REMOVED UNTIL MOBILE IS DEVELOPED -->
    <!-- <button type="button" class="btn btn-primary btn-block logout visible-xs">Log out</button> -->

</div>
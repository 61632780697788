<div class="whiteback" *ngIf="(userState!==undefined && userState.authenticated); else not_loggedIn">
<!----------- Header Bar ---------->

<nav class="navbar navbar-light bg-primary epays_nav">
<span class="navbar-brand text-white epays_nav_title">View & Pay</span>
<div class="navbar-brand form-inline pull-right epays_menu_button_container">


  <!----------- Removed menu button - mobile ---------->

  <!-- <button class="btn btn-sm btn-outline-secondary visible-xs epays_menu_button" type="button" data-toggle="collapse"
    data-target="#menu" aria-expanded="false" aria-controls="menu">
    Menu
  </button> -->

  <!----------- Removed hidden-xs from logout button - mobile ---------->
  <button class="btn btn-sm btn-outline-secondary    epays_menu_button logout_desktop" type="button" (click)="logout()">
    Logout 
  </button>

</div>
</nav>


<!----------- Side Menu ---------->


<app-sidemenu id="menu" class="collapse show" [custFromSession]="custFromSession" [isAdmin]="isAdmin" [isTBSAdmin]="isTBSAdmin"></app-sidemenu>




<!----------- Main Page Container ---------->

<div *ngIf="showCurrentRoute" id="main_container" class="my_container">
    <router-outlet style="left: 0; right: 0;"></router-outlet>
</div>



<!----------- Footer Bar ----------->

<nav class="navbar navbar-light portal_footer">
<span class="navbar-brand text-white footer_title">© 2020 American Airlines, Inc. All Rights Reserved</span>
<div class="navbar-brand form-inline pull-right epays_menu_button_container">
  <a class="btn btn-sm btn-outline-secondary epays_menu_button logout_desktop" href="https://newjetnet.aa.com/docs/DOC-16801" >
    Privacy Policy
  </a>
  <span>| &nbsp;Env: {{envName}}</span>
</div>
</nav>

</div>

<ng-template #not_loggedIn>
<div class="container">
    <!-- <h3> Redirecting to login page...</h3>
    Clicking takes you away from the page.  Auth resources is loaded OnInit
    <button ng-click="getUserInfo()">Login with AppID</button>
    <a class="btn btn-lg btn-primary btn-login hideOnStartup" href={{loginURL}}
  id="loginbutton">Login with AppID</a> -->
</div>
</ng-template>
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Config } from './app/model/config';

fetch("./config/app.config.json")
  .then(response => response.json())
  .then((config: Config) => {
    if (config.production) {
      enableProdMode();
    }
    platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
  })
import { Component, OnInit } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { ConfigService } from './services/config.service';
import { UserState } from './services/user-state';
import { Customer } from './model/Customer';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { AdminService } from './services/admin.service';
import { TBSService } from './services/tbs.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { AccessToken } from './model/AccessToken';
import { LoggedInUser } from './model/LoggedInUser';
import { IneligibleGaurd } from './services/ineligibleGaurd';
import { ReadPropExpr } from '@angular/compiler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'AA View & Pay';
  // loginURL = environment.authServiceURL + "login";
  
  
  currentRoute: string;
  showCurrentRoute: boolean;
  userState: UserState;
  custFromSession: Customer;
  isAdmin: boolean;
  isTBSAdmin: boolean;
  authCode = "";
  accessToken : string;
  loggedInUser : LoggedInUser;
  roles : string[];
  envName : string;

  constructor(private authService: AuthService, private userService: UserService, private adminService: AdminService, private tbsService: TBSService, private http: HttpClient, private router: Router, private inEligibleGuard: IneligibleGaurd, private configService: ConfigService) {
  }

  ngOnInit(): void {
    console.log("calling Get user")
    console.log("app started");

    if(localStorage.getItem('userState') == "true"){
      this.retrieveUserStatus();
    }
    else {
      const url = new URL(window.location.href);

      if (url.searchParams.has('code')) {
          this.authCode = url.searchParams.get('code');
          // this.getToken();
      }
      else if (!this.hasValidToken()) {
          console.log("Go to login during Init");
          this.goToSSOLogin();
      }
         this.retrieveUserStatus();
    }
    
   this.envName = this.configService.config.env;
  }

  public hasValidToken(): boolean {
    let storedToken = localStorage.getItem('ID_TOKEN');
    if (storedToken) {
        return true;
    }
    return false;
}

  /**
   * AUTH SETUP
   */

  public goToSSOLogin() {
    // this.removeToken();
    window.location.href = this.configService.config.authServiceURL;
    // console.log("Get user")

    // this.http.get<UserState>(this.isLoggedUrl, { withCredentials: true, observe: 'response' }).subscribe(resp => {
    //   console.log("User Response received")
    //   console.log(resp.body)
    //   this.userState = resp.body
    // }, (error: any) => { //Can't get user, redirect to login
    //   console.log("cant get user")
    //   // window.location.href = this.loginURL;
    // });
  }

  public requestAccessToken() {
    // https://idptest.aa.com/as/token.oauth2?grant_type=authorization_code&code=9kUkVb9QSmo-8giMDF6af66Fmz6VwgNIuWMAAAAC&redirect_uri=https://pcs-tools-dev.dfspcs.appserviceenvironment.net/
    
    if (!this.authCode || this.authCode.length <= 0) {
        this.goToSSOLogin();
    }
    const body = new HttpParams().set('client_id', this.configService.config.idpClientID);
    return this.http.post<AccessToken>(`https://${this.configService.config.idpEnv}/as/token.oauth2?grant_type=authorization_code&code=${this.authCode}&redirect_uri=${this.configService.config.redirect_url}`, body.toString(), {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
    });
}

  public retrieveUserStatus() {
    if(localStorage.getItem('user')){
      this.loggedInUser = JSON.parse(localStorage.getItem('user'));
      this.userState = new UserState();
      this.userState.authenticated = JSON.parse(localStorage.getItem('userState'));;
      this.assignRoles(this.loggedInUser);
    }
    else{  
      this.requestAccessToken().subscribe(data => {
        if(data){
          this.userState = new UserState();
          this.userState.authenticated = true;
          localStorage.setItem("userState", this.userState.authenticated.toString())
          this.accessToken = data.access_token;
          this.setLocalStorage(this.accessToken);
          this.loggedInUser = this.getUser(this.accessToken);
          //removing debugger
          if(typeof this.loggedInUser.amrappaaviewandpay != "object"){
            var roleArray = new Array<string>;
            roleArray.push(this.loggedInUser.amrappaaviewandpay);
            this.loggedInUser.amrappaaviewandpay = roleArray;
          }
          if(this.loggedInUser.amrappaaviewandpay != undefined){
            this.assignRoles(this.loggedInUser);
          }
          else{
            
            window.location.href = "#/ineligible";
          }
        }
                      
      });
     
    }
  }
  assignRoles(user : LoggedInUser) {
    this.userService.getUserInfo('').subscribe( result => {
      this.custFromSession = result
      this.userService.activate = result.eligible
      if(user.amrappaaviewandpay == undefined || user.amrappaaviewandpay == null)
      { 
        this.inEligibleGuard.activate = true;
      }
      if (!result.eligible) {
        if(this.adminService.activate == true) {
          console.log("I am admin and not eligible")
          window.location.href = "#/admin"
        } else {
          window.location.href = "#/ineligible"
        }
      }
      this.showCurrentRoute = true
    });

if(user.amrappaaviewandpay.length >0 ){

    for (var role of user.amrappaaviewandpay) 
    {
      //Check Basic Admin
      if (role === 'aavp_admin') {
        this.isAdmin = true;
        this.adminService.activate = true; //Updates the service for CanActivate gaurd
        console.log("Confirmed Admin!");
        if (this.userService.activate == false) {
          console.log("And Only Admin!");
          window.location.href = "#/admin";
        }
      }
      //Check TBS User
      if (role === 'tbs_upload') {
        this.isTBSAdmin = true;
        this.tbsService.activate = true; //Updates the service for CanActivate gaurd
        console.log("Confirmed TBS!");
        if (this.userService.activate == false && this.adminService.activate == false) {
          console.log("And Only TBS!");
          window.location.href = "#/tbsupload";
        }
      }
    }
  }
  }
  private getUser(token: string) : LoggedInUser{    
    return JSON.parse(atob(token.split('.')[1])) as LoggedInUser;
   }

//   public removeToken(): void {
//     localStorage.clear();
// }

  setLocalStorage(accessToken : string){
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('user', (atob(this.accessToken.split('.')[1]))); 
    let tempUser = this.getUser(accessToken);
    localStorage.setItem('user_name', tempUser.Name.substring(0, tempUser.Name.indexOf(' '))); 
    localStorage.setItem('id', tempUser.uid);
  }

  public logout() {
    console.log("app logout called");
    window.location.href = this.configService.config.logout_url;
    localStorage.clear();
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '../model/Customer';
import { UserService } from '../services/user.service';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  @Input() custFromSession: Customer;
  @Input() isAdmin: boolean;
  @Input() isTBSAdmin: boolean;
  
  currentBalance: string;
  name: string;
  employeeId: string;

  isEligible: boolean;

  constructor(private userService: UserService, private adminService: AdminService) {
    // this.user = {name: 'TestUser', address: "4255 Amon Carter Blvd, Fort Worth, TX 76155", employeeId: '0010256', primaryEmail: 'test.user1@aa.com', secondarEmail: 'test.user2@aa.com', phone: '480-693-8120'}
  }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.custFromSession) {
      this.isEligible = this.custFromSession.eligible
      this.name = "Welcome, " + this.prettyCase(this.custFromSession.name)
      this.employeeId = "AA ID: " + this.custFromSession.id
    }
  }


  prettyCase(input: String): string {
    return input.toLowerCase().replace(/\b[a-z]/g, c => c.toUpperCase()).replace(',', ' ')
  }


}

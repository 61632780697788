declare var CHECKOUT_APP_PLUGIN_ENV_HOSTNAME: string;
declare function getCheckoutApp(cartID: string, callback: (arg0: CheckoutReceipt) => void, auth: "LOYALTY" | "EMPLOYEE" | undefined): void;

//CheckoutReceipt interfaces 
interface CheckoutReceipt_Success { 
  status: 'SUCCESS';
  data: any;
};

interface CheckoutReceipt_Failure {
  status: 'FAILURE';
  error: 'CANCELLED' | 'MODAL_FAILURE';
};

type CheckoutReceipt = CheckoutReceipt_Success | CheckoutReceipt_Failure;

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ChargeResponse } from '../model/ChargeResponse';
import { OpenCharge } from '../model/OpenCharge';
import { ChargeService } from '../services/chargeService';
import { MatDialog } from '@angular/material/dialog'; 
import { CheckoutService } from '../services/checkout.service';
import { Customer } from '../model/Customer';
import { UserService } from '../services/user.service';
import { PaymentSuccessDialogComponent } from '../dialogs/payment-success-dialog/payment-success-dialog.component';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-account-activity',
  templateUrl: './account-activity.component.html',
  styleUrls: ['./account-activity.component.scss'],
  host: {
    class: 'main_content'
  }
})
export class AccountActivityComponent implements OnInit {
  @Input() isNested: boolean;
  @Input() chargeResponse: ChargeResponse;
  charges: OpenCharge[];
  currentBalance: String;
  amountHeader: String;
  hasRecoveryAmount: Boolean;
  selectedChargeType: string = 'Fee';
  totalSelectedGrossAmount: number = 0;
  customerId: string;
  customerEmail1: string; 
  customerEmail2: string; 
  name: string;
  pnr: string = '';
  merchantRef: string;
  selectedCharges: Array<any> = []; //storing selected charges with reuired fields
  jsonPayload: string = ''; //storing JSON payload to verify and validate
  journalEntryApiUrl: string;

  constructor(private chargeService: ChargeService, private dialog: MatDialog, private checkoutService: CheckoutService, private userService: UserService, private configService: ConfigService ) {

  }

  ngOnInit() {

    // To store selected charges, if user jumps on other tabs without completing transaction
    window.onbeforeunload = () => {
      sessionStorage.removeItem('selectedCharges');
    };

    if (this.isNested != true) {
      console.log("Page requesting charges")
      this.chargeService.getCharges('').subscribe(result => {
        this.chargeResponse = result;
        if(result.charges && result.charges.length > 0) {
          this.configureTableData();
          this.calculateTotalSelectedGrossAmount();
          this.storeChargesInLocalStorage();

          const storedCharges = sessionStorage.getItem('selectedCharges');
          if (storedCharges) {
            this.selectedCharges = JSON.parse(storedCharges);
            console.log('Restored selected charges from sessionStorage:', this.selectedCharges);
          }
        }
      });
    }
    // Sending customer details for email notification
    this.userService.getUserInfo('mock').subscribe({
      next: (customer) => {
        if (customer) {
          this.customerId = customer.id; // Store the entire customer object
          console.log('Customer fetched:', this.customerId);
          if(customer.email1 != null && customer.email1 != ""){
            this.customerEmail1 = customer.email1; // Store email1
          }
          if(customer.email2 != null && customer.email2 != ""){
            this.customerEmail2 = customer.email2; // Store email2
          }
          this.name = customer.name;          
        } else {
          console.warn('No valid customer data found');
        }
      },
      error: (err) => {
        console.error('Error fetching user info:', err);
      }
    });
  
  }

  ngOnChanges(charge: OpenCharge) {
    this.configureTableData();
    this.calculateTotalSelectedGrossAmount();
    this.selectedChargesPayload(charge);
    console.log('onCheckboxChange invoked. Current selectedCharges:', this.selectedCharges);
  }

  configureTableData() {
    console.log("Configuring Table Data");
    this.charges = this.chargeResponse.charges;
    this.amountHeader = this.chargeResponse.anyHEC ? "Gross Amount" : "Amount";
    this.currentBalance = Number(this.chargeResponse.currentBalance)
      .toLocaleString(undefined, { style: 'currency', currency: this.chargeResponse.balanceCurrency, minimumFractionDigits: 2 })
      + " " + this.chargeResponse.balanceCurrency;
  
    // This checks if data has already been formatted
    if (!this.chargeResponse.formatted) {
      for (let currentCharge of this.charges) {  
        // Format the grossAmount for each charge
        currentCharge.grossAmount = currentCharge.grossAmount
          ? Number(currentCharge.grossAmount).toLocaleString(undefined, { style: 'currency', currency: currentCharge.currency, minimumFractionDigits: 2 }) + " " + currentCharge.currency
          : "N/A";
  
        // Format the recoveryAmount if it exists
        if (currentCharge.recoveryAmount != null && currentCharge.recoveryAmount.length > 0) {
          this.chargeResponse.hasRecoveryAmount = true;
          currentCharge.recoveryAmount = Number(currentCharge.recoveryAmount)
            .toLocaleString(undefined, { style: 'currency', currency: currentCharge.currency, minimumFractionDigits: 2 }) + " " + currentCharge.currency;
        } else {
          currentCharge.recoveryAmount = "N/A";
        }
      }
      this.chargeResponse.formatted = true;
    }
    this.hasRecoveryAmount = this.chargeResponse.hasRecoveryAmount;
    this.storeChargesInLocalStorage();
  }

storeChargesInLocalStorage() {
  const chargesToStore = this.charges.map(charges => ({
      fiscalYear: charges.fiscalYear,
      lineItem: charges.lineItem,
      poNumber: charges.poNumber,
      longText: charges.longText
  }));

  localStorage.setItem('chargesData', JSON.stringify(chargesToStore));
  console.log("Local storage data:", localStorage.getItem('chargesData')); // Log to verify data is stored
}

  isSelectedCharge(charge: OpenCharge): boolean {
    const isSelected = charge.selected;
    if (isSelected) {
      this.calculateTotalSelectedGrossAmount();
    }

    

    localStorage.setItem('selectedCharges in Is selected charge', JSON.stringify(OpenCharge));
    console.log('Selected Charges stored in is selected:', OpenCharge);
    return isSelected;
  }

  isOverpaymentValid(overpaymentDate: Date): boolean {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); 

    const overpaymentDateObject = new Date(overpaymentDate);

    let firstDayOfMarch: Date;
    if (overpaymentDateObject.getFullYear() === currentYear) {
        firstDayOfMarch = new Date(currentYear + 1, 2, 1); 
    } else {
        firstDayOfMarch = new Date(currentYear, 2, 1); 
    }

    const isValid = overpaymentDateObject < firstDayOfMarch;
    const isCurrentYear = overpaymentDateObject.getFullYear() === currentYear;
    const isValidOverall = isValid && isCurrentYear;

    return isValidOverall;
  }
  
  selectedChargesPayload(charge: OpenCharge) {
    // Generate a unique key for the charge
    const uniqueKey = `${charge.lineItem}-${charge.referenceNumber}-${charge.chargeType}-${charge.grossAmount}`;
  
    if (charge.selected) {
      // Check if the charge is already in selectedCharges
      const existingCharge = this.selectedCharges.find(item => 
        `${item.lineItem}-${item.referenceNumber}-${item.chargeType}-${item.grossAmount}-${item.activityDate}` === uniqueKey
      );
  
      if (!existingCharge) {
        const isOverPaymentValid = this.isOverpaymentValid(new Date(charge.overpaymentDate));
  
        const selectedCharge = {
          lineItem: charge.lineItem,
          referenceNumber: charge.referenceNumber,
          chargeType: charge.chargeType,
          grossAmount: charge.grossAmount,
          fiscalYear: charge.fiscalYear,
          poNumber: charge.poNumber,
          longText: charge.longText,
          recoveryAmount: charge.recoveryAmount,
          selectedCharge: charge.selected,
          isOverPaymentValid: isOverPaymentValid,
        };
  
        this.selectedCharges.push(selectedCharge);
        console.log('Charge added to selectedCharges:', selectedCharge);
      }
    } else {
      // Remove the charge using the unique key
      this.selectedCharges = this.selectedCharges.filter(item => 
        `${item.lineItem}-${item.referenceNumber}-${item.chargeType}-${item.grossAmount}` !== uniqueKey
      );
      console.log('Charge removed from selectedCharges:', charge);
    }
  
    // Update localStorage with the new selectedCharges array
    localStorage.setItem('selectedCharges', JSON.stringify(this.selectedCharges));
    sessionStorage.setItem('selectedCharges', JSON.stringify(this.selectedCharges));
    console.log('Updated selectedCharges:', this.selectedCharges);
  }
  
  calculateTotalSelectedGrossAmount() {
    this.totalSelectedGrossAmount = this.charges.reduce((total, charge) => {
      if (charge.selected) {
        if (charge.recoveryAmount !== "N/A" && charge.grossAmount !== "N/A") {
          total += parseFloat(charge.recoveryAmount.replace(/[^\d.-]/g, '').replace(/,/g, ''));
        } else {
          total += parseFloat(charge.grossAmount.replace(/[^\d.-]/g, '').replace(/,/g, ''));
        }
      }
      return total;
    }, 0);
    
  }
  // To get the negative symbol before the $
  formatBalance(totalSelectedGrossAmount: number | null | undefined): string {
    if (totalSelectedGrossAmount === null || totalSelectedGrossAmount === undefined) {
      return '$0.00'; // Default
    }
  
    if (totalSelectedGrossAmount < 0) {
      // Format for negative values
      return `-$${Math.abs(totalSelectedGrossAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    } else {
      // Format for positive values
      return `$${totalSelectedGrossAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }
  }
  

  onCheckboxChange(charge: OpenCharge) {
    this.calculateTotalSelectedGrossAmount();
    this.selectedChargesPayload(charge);
    
  }

  callCheckoutAPI() {
  // For PNR/Merchant Ref ID. Generate formattedDate for PNR
  
  const now = new Date();
  const formattedDate = now.getFullYear().toString().slice(-2) +
    String(now.getMonth() + 1).padStart(2, '0') +
    String(now.getDate()).padStart(2, '0') +
    String(now.getHours()).padStart(2, '0') +
    String(now.getMinutes()).padStart(2, '0') +
    String(now.getSeconds()).padStart(2, '0') +
    String(now.getMilliseconds()).padStart(3, '0');

  // For custom UUID which is for ItemID
  function generateCustomUUID() {
    const randomPart = () =>
      Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return (
      randomPart() + randomPart() + '-' +
      randomPart() + '-' +
      randomPart() + '-' +
      randomPart() + '-' +
      randomPart() + randomPart() + randomPart()
    );
  }
  const itemId = generateCustomUUID();

  const requestBody = {
    checkoutRequest: {
      cart: {
        metadata: {
          locale: "en_US",
          clientID: "VIEWANDPAY",
          journeyPath: "VIEWANDPAY",
          transactionProcessedStatus: "NOT_COMPLETED"
        },
        airline: {
          pnr: "VP_" + formattedDate,
          slices: [],
          passengers: []
        },
        EMPLOYEE_VIEWANDPAY: [
          {
            itemID: itemId,
            orderType: "EMPLOYEE_VIEWANDPAY",
            pricingAttributes: {
              basePrice: {
                value: this.totalSelectedGrossAmount,
                currencyCode: "USD"
              },
              tax: {
                value: 0.0,
                currency: "USD",
                code: "",
                country: "United States"
              }
            },
            productType: "EMPLOYEE_VIEWANDPAY"
          }
        ]
      }
    },name: this.name,
      merchantRef: "VP_" +formattedDate, // PNR
    selectedCharges: this.selectedCharges.map(charge => ({
      ...charge,
      recoveryAmount: charge.recoveryAmount || null,
      isOverPaymentValid: charge.isOverPaymentValid
    }))
  };

  // Calling the checkout API to generate a cart ID
  this.checkoutService.postCheckout(requestBody).subscribe(
    response => {
      console.log('Checkout response:', response);
      if (response && response.cartId) {
        localStorage.setItem('cartId', response.cartId);
        localStorage.setItem('merchantRef', "VP_" + formattedDate);

        // Pass formattedDate to startCheckoutApp
        this.startCheckoutApp(response.cartId, formattedDate);
      }
    },
    error => {
      console.error('Error during checkout:', error);
    }
  );
}

// Function to call CheckoutApp plugin
startCheckoutApp(cartId: string, formattedDate: string) {
  getCheckoutApp(cartId, (receipt: CheckoutReceipt) => {
    if (receipt.status === 'SUCCESS') {
      console.log('Payment successful:', receipt.data);

      const paymentData = {
        pspReferenceNumber: receipt.data.pspReferenceNumber,
        last4Digits: receipt.data.paymentDetails.lastFourDigit,
        cardType: receipt.data.paymentDetails.cardType,
        expDate: receipt.data.paymentDetails.expDate,
        approvalCode: receipt.data.approvalCode,
        amount: receipt.data.amount.value,
        currencyCode: receipt.data.amount.currencyCode,
        id: this.customerId,
        name: this.name,
        email1: this.customerEmail1, // Include email1
        email2: this.customerEmail2, // Include email2
        formattedDate: formattedDate, // Use formattedDate here
        currentTime: Date.now().toString(),
        selectedCharges: this.selectedCharges.map(charge => ({
          ...charge,
          recoveryAmount: charge.recoveryAmount || null, // Include recoveryAmount when available
          isOverPaymentValid: charge.isOverPaymentValid
        }))
      };

      // Store paymentData in localStorage for debugging
      localStorage.setItem('paymentData', JSON.stringify(paymentData));
      console.log('Payment data stored in localStorage:', paymentData);
      this.dialog.open(PaymentSuccessDialogComponent, {
        width: '400px',
        disableClose: true,
      });

      // Send paymentData to the Java service
      this.sendPaymentData(paymentData);
      this.resetCheckboxes();
    } else if (receipt.status === 'FAILURE') {
      console.error('Payment failed:', receipt.error);
      if (receipt.error === 'CANCELLED') {
        console.warn('User cancelled the payment. Refreshing the page.');
        location.reload(); // Refresh the page
      }
    }
  }, undefined); // No auth required for EMPLOYEE
}

  
  sendPaymentData(paymentData: any) {
    console.log('sendPaymentData invoked with:', paymentData);
    if (!this.configService?.config?.aavpGatewayUrl) {
      console.error("AAVP Gateway URL is not defined");
      return;
  }
    

    this.journalEntryApiUrl = `${this.configService.config.aavpGatewayUrl}aavp/checkout/sendjournalentry`;
    fetch(this.journalEntryApiUrl,{

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('access_token')
      },
      body: JSON.stringify(paymentData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to send payment data');
        }
        return response.json();
      })
      .then(data => {
        console.log('Payment data successfully sent:', data);
      })
      .catch(error => {
        console.error('Error sending payment data:', error);
        console.log('Request which caused the Error in SendJournalEntry:', paymentData);
      });
  }

  resetCheckboxes() {
    this.charges.forEach(charge => {
      charge.selected = false;
    });
  
    this.selectedCharges = [];
  
    this.totalSelectedGrossAmount = 0;
  
    console.log('All checkboxes have been reset and selected charges cleared.');
  }
  
}
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Customer } from '../model/Customer';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
  host: {
    class: 'info_full_width'
  }
})
export class CustomerInfoComponent implements OnInit , AfterViewInit{
  
  //Recieves input from Parents
  @Input() user: Customer;
  @Output() heightSet = new EventEmitter<Number>();
  @ViewChild('customerInfo', {static: false}) customerInfo : ElementRef;

  constructor(private userService: UserService) {
    // this.user = {name: 'TestUser', address: "4255 Amon Carter Blvd, Fort Worth, TX 76155", employeeId: '0010256', primaryEmail: 'test.user1@aa.com', secondarEmail: 'test.user2@aa.com', phone: '480-693-8120'}
  }

  ngOnInit() {
    if (!this.user) {
      this.userService.getUserInfo('').subscribe(result => {
        this.user = result
      });
    }
    this.user.name = this.prettyCase(this.user.name)
    this.user.address = this.prettyCase(this.user.address)
  }

  ngAfterViewInit() {
    console.log(this.customerInfo.nativeElement.offsetHeight)
    this.heightSet.emit(this.customerInfo.nativeElement.offsetHeight)
  }


  prettyCase(input: String): string {
    return input.toLowerCase().replace(/\b[a-z]/g, c => c.toUpperCase())
  }


}


import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AccountActivityComponent } from './account-activity/account-activity.component';
import { AdminComponent } from './admin/admin.component';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { PopupComponent } from './popup/popup.component';
import { ResultsComponent } from './results/results.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { TbsUploadComponent } from './tbs-upload/tbs-upload.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { IneligibleComponent } from './ineligible/ineligible.component';
import { VersionInfoComponent } from './version-info/version-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { UserService } from './services/user.service';
import { AdminService } from './services/admin.service';
import { TBSService } from './services/tbs.service';
import { AuthService } from './services/auth.service';
import { ChargeService } from './services/chargeService';
// import { HttpRequestInterceptor } from './services/HttpRequestInterceptor';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgOverlayContainerModule } from 'ng-overlay-container';
import { AppOverlayContainer } from './custom-overlay-container';
import { IneligibleGaurd } from './services/ineligibleGaurd';
import { HttpRequestInterceptor } from './services/HttpRequestInterceptor';
import { ConfigService } from './services/config.service';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    LandingpageComponent,
    VersionInfoComponent,
    AccountActivityComponent,
    AdminComponent,
    SidemenuComponent,
    CustomerInfoComponent,
    IneligibleComponent,
    HelpPageComponent,
    PopupComponent,
    TbsUploadComponent,
    ResultsComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    NgOverlayContainerModule,
    RouterModule.forRoot( [
      { path: 'activity', component: AccountActivityComponent, canActivate: [UserService]},
      { path: 'admin', component: AdminComponent, canActivate: [AdminService]},
      //Add TBS Componenet here with canActivate: [TBSService]
      { path: 'tbsupload', component:TbsUploadComponent, canActivate: [TBSService]},
      { path: 'results', component:ResultsComponent, canActivate: [TBSService]},
      { path: 'info', component: CustomerInfoComponent, canActivate: [UserService]},
      { path: 'ineligible', component: IneligibleComponent, canActivate: [IneligibleGaurd]},
      { path: 'help', component: HelpPageComponent, canActivate: [UserService]},
      { path: '**', redirectTo: 'activity'}
    ], {useHash: true})
  ],
  providers: [ConfigService, AuthService, UserService, ChargeService, AdminService, TBSService, IneligibleGaurd, 
    {provide: APP_INITIALIZER, useFactory: (configService: ConfigService) => { return () => configService.load()}, deps: [ConfigService], multi:true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}, 
    { provide: OverlayContainer, useFactory: (): AppOverlayContainer => new AppOverlayContainer() },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// trigger build
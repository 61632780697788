<div class="balance-container">
    <!-- Title -->
    <h1 class="title">Account Activity</h1>

    <!-- Current Balance -->
    <h4 id="currentBalance" class="current_balance">
        <span class="subtitle">Current Balance: </span>
        <span class="balance">{{ currentBalance }}</span>
    </h4>

    <!-- Amount Being Paid -->
    <h4 id="amountBeingPaid" class="amount_being_paid">
        <span class="subtitle">Amount Being Paid: </span>
        <!-- <span class="balance">
            ${{ totalSelectedGrossAmount ? totalSelectedGrossAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00' }} USD
        </span> -->
        <span class="balance">
            {{ formatBalance(totalSelectedGrossAmount) }}
        </span>
        
    </h4>

    <!-- Pay Now Button -->
    <div class="pay_now_container" style="margin-left: 10px;">
        <button
            class="pay_now_button"
            [class.disabled]="totalSelectedGrossAmount <= 0"
            [disabled]="totalSelectedGrossAmount <= 0"
            (click)="callCheckoutAPI()"
        >
            Pay Now by Credit Card
        </button>
    </div>
</div>

<!-- Charges Table -->
<div class="table_container">
    <table class="aavp_table" style="overflow-y: hidden; overflow-x: auto;">
        <!-- Table Header -->
        <tr class="aavp_table_header">
            <th class="center-align">Pay Charges</th>
            <th class="center-align">Charge Type</th>
            <th class="center-align">Reference Number</th>
            <th class="center-align">Paycheck Reference Date</th>
            <th class="center-align" *ngIf="hasRecoveryAmount">Overpayment Recovery Amount</th>
            <th class="center-align">{{ amountHeader }}</th>
            <th class="center-align">Activity Date</th>
            <th class="center-align">Due Date</th>
        </tr>

        <!-- Table Rows -->
        <tr class="aavp_table_row" *ngFor="let charge of charges">
            <!-- Checkbox for Selecting Charges -->
            <td class="center-align">
                <input
                    type="checkbox"
                    name="payCharges"
                    [(ngModel)]="charge.selected"
                    (change)="onCheckboxChange(charge)"
                />
            </td>
            <td class="center-align">{{ charge.chargeType }}</td>
            <td class="center-align">{{ charge.referenceNumber || "N/A" }}</td>
            <td class="center-align">{{ charge.overpaymentDate | date: 'MM/dd/yyyy':'+0000' || "N/A" }}</td>
            <ng-container *ngIf="hasRecoveryAmount">
                <td class="center-align">{{ isOverpaymentValid(charge.overpaymentDate) ? charge.recoveryAmount : 'N/A' }}</td>
            </ng-container>
            <td class="center-align">{{ charge.grossAmount }}</td>
            <td class="center-align">{{ charge.documentDate | date: 'MM/dd/yyyy':'+0000' || "N/A" }}</td>
            <td class="center-align">{{ charge.paymentDue | date: 'MM/dd/yyyy':'+0000' || "N/A" }}</td>
        </tr>
    </table>
</div>
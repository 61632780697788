import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    
      // console.log("interceptor: " + req.url);
      let req;
      if(!request.url.startsWith("https://idp")){
      req = request.clone({
        headers: request.headers
        .set('aavp-id', localStorage.getItem('id'))
        .set('aavp-name', localStorage.getItem('user_name'))
        .set('token', localStorage.getItem('access_token'))
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Request-Methods', '*')
        .set('Access-Control-Allow-Credentials', 'true')
      });
    }
    else{
      req = request.clone({
        withCredentials: true, 
      });
    }
      console.log("AAVP ID :" + localStorage.getItem('id'));
      return next.handle(req);
  }
}
<div class="ui-g ">
    <div class="ui-g-12 ui-md-6 ui-lg-2">
        <!-- <p-card header="My Information" styleClass="ui-card-shadow">
            <div class="address user-name"><i class="fa fa-user" aria-hidden="true"></i> | {{name}}</div>
            <div class="address user-empId"><i class="fa fa-id-badge" aria-hidden="true"></i> | #{{employeeId}}
            </div>
            <hr>
            <div class="address user-addr"><i class="fa fa-map-marker" aria-hidden="true"></i> | {{address}}</div>
            <hr>
            <div class="address user-primaryEmail"><i class="fa fa-envelope" aria-hidden="true"></i> |
                {{primaryEmail}}</div>
            <div class="address user-secondaryEmail"><i class="fa fa-envelope" aria-hidden="true"></i> |
                {{secondaryEmail}}</div>
            <hr>
            <div class="address user-phone"><i class="fa fa-phone"></i> | {{phone}}</div>
        </p-card> -->
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-8">
        <div>
            <img class="landing-page-image" alt="/" src="assets/img/american-airlines-image.jpg" />
        </div>
        <div> Welcome to the Receivables Payment Portal message & navigation instructions</div>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-2">
        <!-- <p-card header="Current Balance" styleClass="ui-card-shadow">
            <div class="address user-name"><i class="fa fa-money" aria-hidden="true"></i> | ${{currentBalance}}</div>
        </p-card> -->

    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Customer } from '../model/Customer';
import { ChargeResponse } from '../model/ChargeResponse';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  host: {
    class: 'main_content'
  }
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminComponent implements OnInit{
  user: Customer;
  chargeResponse: ChargeResponse;
  currentBalance: String;
  custInfoHeight: Number;

  constructor(private adminService: AdminService) {

  }

  ngOnInit() {
  }


  searchWith(id: string) {
    this.user = null;
    this.chargeResponse = null;
    if(!id.startsWith("E")){
     id =  "E" + id;
    }

    console.log("Searching with: " + id)

    this.adminService.getUserInfo(id).subscribe(result => {
      console.log('Result: ' + result);
      this.user = result
    });

    this.adminService.getCharges(id).subscribe(result => {
      this.chargeResponse = result;
    });

  }

  onHeightSet(height: Number) {
    console.log(`Parent Recieved ${height}`)
    this.custInfoHeight = height
  }

}

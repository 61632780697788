import { Component, OnInit } from '@angular/core';
import { Customer } from '../model/Customer';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  // items: MenuItem[];

  user: Customer;

  name = "Test";

  constructor(private adminService: AdminService) {
  }

  ngOnInit() {
    // this.items = [
    //   { label: 'Employee Portal', routerLink: ['/home'], icon: 'fa fa-fw fa-2x fa-home' },
    //   // {label: 'Notifications', icon: 'fa fa-2x fa-envelope'},
    //   { label: 'View Charges', routerLink: ['/charges'], icon: 'fa fa-2x fa-credit-card' },
    //   // {label: 'Account History', icon: 'fa fa-2x fa-history'},
    //   // {label: 'Pay Plan', icon: 'fa fa-2x fa-list-alt'},
    //   { label: 'Help/FAQ', icon: 'fa fa-fw fa-2x fa-question-circle' }
    // ];

    this.shouldShowAdmin();
    
    // this.user.name = "Test Name"
    this.name = "Name"
  }


  shouldShowAdmin() {
    
  }

}



import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, EMPTY, of, } from 'rxjs';
import { shareReplay, catchError } from 'rxjs/operators'
import { OpenCharge } from '../model/OpenCharge';
import { ChargeResponse } from '../model/ChargeResponse';
import { ConfigService } from './config.service';



@Injectable({
    providedIn: 'root',
})
export class ChargeService {

    //user = JSON.parse(localStorage.getItem('user')) as LoggedInUser;

    charge: Observable<ChargeResponse>

    constructor(private http: HttpClient, private configService : ConfigService) { }

    public getCharges(customerNo: String): Observable<ChargeResponse> {

        if (this.charge) {
            console.log('Returning cached value!')
            return this.charge
        }

        console.log('Do the charge request again')

        let temp: Observable<ChargeResponse>
        if (customerNo === 'mock' || false) { //Use mocked observable for cache process
            let mock = new OpenCharge('DFW-DAY D1 FIRST THIS IS VERY LONG FOR A TEST WHAT WHAT LONG', 'USD', new Date(), "260.00", new Date(), new Date(), "260.00", 9902159153)
            let mock1 = new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153)
            let res = new ChargeResponse("123.45", "USD", [mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1, mock, mock1], false)
            temp = of(res)
        } else {
            temp = this.http.get<ChargeResponse>(`${this.configService.config.aavpGatewayUrl}customer/charges`)
        }

        this.charge = temp.pipe(
            shareReplay(1),
            catchError(err => {
                delete this.charge;
                console.log(err)
                return EMPTY;
            }));
        return this.charge
    }

}
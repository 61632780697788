<div>
    <h1 class="title">Admin</h1>
</div>

<!-- Search input.  Add 'value="mock"' to search box below for testing -->

<div class="lookupInput">
    <input #searchId type="text" pInputText placeholder="Customer ID" class="txtBox" id="idInput">
    <input type="submit" name="lookup" value="Lookup" id="lookupBtn" (click)="searchWith(searchId.value)"
        class="epays_form btn" />
</div>

<!-- <table class="lookupInput">
    <tr>
        <td width="200px"><input #searchId type="text"  placeholder="Customer ID" class="txtBox"></td>
        <td valign="middle"><input type="submit" name="lookup" value="Lookup" id="lookupBtn" (click)="searchWith(searchId.value)"
            class="epays_form btn" /></td>
    </tr>
</table> -->


<!-- Results Div -->

<div *ngIf="user != null || chargeResponse != null" class="lookupResults">
    <!-- <div [ngStyle]="{'height': (custInfoHeight > 0) ? 'calc(100% +' + custInfoHeight + 'px)' : '550px'}" style="overflow:hidden; display: flex; flex-direction: column"> -->
    <div style="overflow:hidden; display: flex; flex-direction: column">
        <app-customer-info *ngIf="user != null" #customerInfo [user]="user" (heightSet)="onHeightSet($event)"></app-customer-info>
        <app-account-activity *ngIf="chargeResponse != null" [chargeResponse]="chargeResponse" [isNested]="true"></app-account-activity>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, EMPTY, of, } from 'rxjs';
import { tap, shareReplay, catchError } from 'rxjs/operators'
import { Customer } from '../model/Customer';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigService } from './config.service';


@Injectable()
export class UserService implements CanActivate {

    customer: Observable<Customer>;
    activate: boolean;

    constructor(private http: HttpClient, private configService : ConfigService) {
        // this.cached = new Observable<Customer>
    }

    public getUserInfo(customerNo: String): Observable<Customer> {

        if (this.customer) {
            console.log('Returning cached value!')
            return this.customer
        }

        console.log('Do the charge request again')
        let temp: Observable<Customer>
        if (customerNo === 'mock' || false) {
            // temp = of({
            //     existsInSAP: false, name: 'TestUser', id: "00123456", email1: 'test.user1@aa.com', email2: 'test.user2@aa.com', phone: '480-867-5309',
            //     address: "4255 Amon Carter Blvd, Fort Worth, TX 76155", groupCodes: null, eligible: false
            // });
            temp = of({
                existsInSAP: true, name: 'TestUser', id: "00123456", email1: 'test.user1@aa.com', email2: 'test.user2@aa.com', phone: '480-867-5309',
                address: "4255 Amon Carter Blvd, Fort Worth, TX 76155", groupCodes: null, eligible: true
            });
        } else {
            temp = this.http.get<Customer>(`${this.configService.config.aavpGatewayUrl}customer/fromSession`);
        }
        this.customer = temp.pipe(
            shareReplay(1),
            catchError(err => {
                delete this.customer;
                console.log(err)
                return EMPTY;
            }));
        return this.customer
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.activate || this.activate === undefined) {
            return true
        } else {
            return false
        }

    }


}

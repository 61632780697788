import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, EMPTY, of, } from 'rxjs';
import { tap, shareReplay, catchError, map } from 'rxjs/operators'
import { Customer } from '../model/Customer';
import { Admin } from '../model/Admin';
import { ChargeResponse } from '../model/ChargeResponse';
import { OpenCharge } from '../model/OpenCharge';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { LoggedInUser } from '../model/LoggedInUser';
import { ConfigService } from './config.service';



@Injectable()
export class AdminService implements CanActivate {
    activate: boolean

    constructor(private http: HttpClient, private router: Router, private configService : ConfigService) { 
        // this.cached = new Observable<Customer>
    }

    // user = JSON.parse(localStorage.getItem('user')) as LoggedInUser;

    public getUserInfo(customerNo: String): Observable<Customer> {
        if (customerNo === 'mock') {

            return of({existsInSAP: false, name: 'TestUser', id: "00123456", email1: 'test.user1@aa.com', email2: 'test.user2@aa.com', phone: '480-867-5309', address: "4255 Amon Carter Blvd, Fort Worth, TX 76155", groupCodes: null, eligible: true });

        } else {

            return this.http.get<Customer>(`${this.configService.config.aavpGatewayUrl}admin/searchCustomerById?${customerNo}`);

        }        
    }

    public getCharges(customerNo: String): Observable<ChargeResponse> {
        if (customerNo === 'mock') {
            // TODO: Use service virtualization here
            let res = new ChargeResponse("123.45", "USD", [
                new OpenCharge('DFW-DAY D1 FIRST THIS IS VERY LONG FOR A TEST WHAT WHAT LONG', 'USD', new Date(), "260.00", new Date(), new Date(), "260.00", 9902159153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Payment', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153),
                new OpenCharge('Last One', 'USD', new Date(), "100.00", new Date(), null, "100.00", 194629153)
            ], false)
            return of(res);

        } else {
            return this.http.get<ChargeResponse>(`${this.configService.config.aavpGatewayUrl}admin/searchChargesById?${customerNo}`);
        }
        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (this.activate) {
            return true
        } else {
            return true;
        }

    }
}
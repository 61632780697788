<h1 class="title">Mass Upload</h1>


<div class="section">

    <p class="message">Please upload the xml file! Only 1 upload allowed.</p>

    <input #xmlInput style="padding: 0 0 20px 20px;" hidden="true" type="file" (change)="xmlInputChange($event)"
        accept=".xml" />

    <button [disabled]="!showDelete" class="portal_button btn" style="margin-left: 20px;" (click)="onSubmit()"
        id="btnUpload">Submit</button>
    <button class="portal_button delete_button btn" color="warn" id="btnDelete" *ngIf="showDelete"
        (click)="deleteFile()">Delete</button>

</div>

<div class="section">
    <h1 class="title">Results{{selectedFileName}}</h1>

    <div *ngIf="!fileID" style="margin-left: 20px;">
        <mat-table  [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="filename">
                <th mat-header-cell *matHeaderCellDef style="width:250px; font-weight: bold;"> Mass Upload File Name </th>
                <td mat-cell *matCellDef="let element" style="width:250px;padding: 20px 20px; border: none;">
                    <a *ngIf="element.completed" (click)="getfileID(element.id, element.filename);" style= "cursor: pointer">{{element.filename}}</a>
                    <a *ngIf="!element.completed" class="disable">{{element.filename}}</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="timestamp">

                <th mat-header-cell *matHeaderCellDef style="width:250px; font-weight: bold;"> Timestamp </th>
                <td mat-cell *matCellDef="let element" style="width:250px; border: none;"> {{element.timestamp | date: 'MM/dd/yyyy HH:mm'}} </td>

            </ng-container>
            <ng-container matColumnDef="userId">
                <th mat-header-cell *matHeaderCellDef style="width:150px; font-weight: bold;"> User ID </th>
                <td mat-cell *matCellDef="let element" style="width:150px; border: none;"> {{element.userId}} </td>

            </ng-container>
            <ng-container matColumnDef="invoiceCount">
                <th mat-header-cell *matHeaderCellDef style="width:150px;font-weight: bold;"> Invoice Count </th>
                <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 20px; border: none;">
                    {{element.invoiceCount}} </td>
                    

            </ng-container>
            <ng-container matColumnDef="success">

                <th mat-header-cell *matHeaderCellDef style="width:150px; font-weight: bold;"> Success Count </th>
                <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 20px; border: none;">
                    {{element.successCount}} <mat-spinner *ngIf="!element.completed" [diameter]="20"></mat-spinner></td>
                    

            </ng-container>
            <ng-container matColumnDef="failure">
                <th mat-header-cell *matHeaderCellDef style="width:150px;font-weight: bold;"> Failure Count </th>
                <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 20px; border: none;">
                    {{ element.invoiceCount > 0 ? element.invoiceCount - element.successCount : ''}} </td>

            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{'background-color':row.completed ? 'white' : 'lightgreen' }"></mat-row>

        </mat-table>
        <mat-paginator #paginator (page)="retrieveResults($event)" [pageSize]="pageSize"
            [pageIndex]="pageIndex" [length]="totalSize" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
        </mat-paginator>


    </div>

    <app-results *ngIf="fileID" [fileID]="fileID"></app-results>

    <button class="portal_button btn" id="btnBack" *ngIf="fileID" (click)="goBack()">Back</button>
</div>


<h1 class="title">Master Data</h1>

<div *ngIf="statusResponse">

    <table class="master_data_table">
        <tr style="width: 100%">
            <td style="width:30%;">

                <input [disabled]="statusResponse.costCenter.refreshing" type="button" name="btnCCRefresh"
                    value="Cost Center Load" id="btnCCRefresh" (click)="refreshCostCenter()"
                    class="portal_button btn" />

            </td>
            <td *ngIf="!statusResponse.costCenter.refreshing" class="status_row">
                Last Cost Center Data Load on {{(statusResponse.costCenter.lastRefresh | date: 'MM/dd/yyyy':'+0000')}}
                by {{statusResponse.costCenter.updateBy}}
            </td>
            <td *ngIf="statusResponse.costCenter.refreshing" class="status_row">
                Cost Center Load in Progress
            </td>
        </tr>

        <tr style="width: 100%">
            <td style="width:30%;">
                <input [disabled]="statusResponse.materialAccount.refreshing" type="button" name="btnMatAccntRefresh"
                    value="Material/GL Account Load" id="btnMatAccntRefresh" (click)="refreshMaterialAccount()"
                    class="portal_button btn" />

            </td>
            <td *ngIf="!statusResponse.materialAccount.refreshing" class="status_row">
                Last Material/GL Accounts Data Load on
                {{(statusResponse.materialAccount.lastRefresh | date: 'MM/dd/yyyy':'+0000')}} by
                {{statusResponse.materialAccount.updateBy}}
            </td>
            <td *ngIf="statusResponse.materialAccount.refreshing" class="status_row">
                Material/GL Accounts Load in Progress
            </td>
        </tr>

        <tr style="width: 100%">
            <td style="width:30%;">

                <input [disabled]="statusResponse.fsg.refreshing" type="button" name="btnFsgLoad" value="Fsg Load"
                    id="btnFsgLoad" (click)="refreshFSG()" class="portal_button btn" />

            </td>
            <td *ngIf="!statusResponse.fsg.refreshing" class="status_row">
                Last Data Load on {{(statusResponse.fsg.lastRefresh | date: 'MM/dd/yyyy':'+0000')}} by
                {{statusResponse.fsg.updateBy}}

            </td>
            <td *ngIf="statusResponse.fsg.refreshing" class="status_row">
                Fsg Load in Progress
            </td>
        </tr>

    </table>

    <h4 class="note">
        Note: Invoking this process for each category will replace existing data with current data from SAP.
    </h4>

</div>
import { OpenCharge } from './OpenCharge';

export class ChargeResponse {
    currentBalance: string;
    balanceCurrency: string;
    charges: OpenCharge[];
    anyHEC: boolean;
    hasRecoveryAmount: boolean;
    formatted: boolean;

    constructor(currentBalance: string, balanceCurrency: string, charges: OpenCharge[], anyHEC: boolean) {
        this.currentBalance = currentBalance
        this.balanceCurrency = balanceCurrency
        this.charges = charges
        this.anyHEC = anyHEC;
    }

}

import { Injectable } from "@angular/core";
import { Config } from "../model/config";


@Injectable({
    providedIn: 'root'
  })
  export class ConfigService {
  
    private _config: Config | undefined
  
    public get config() : Config {
      if (!this._config) {
        throw new Error("No config found");
      }
      return this._config
    }
  
    async load() {
      const response = await fetch(`/config/app.config.json`);
      this._config = await response.json() as Config
    }
  }
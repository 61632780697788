<div #customerInfo class="info_container" id="menu">

    <div>
        <h1 class="title">Contact information</h1>
    </div>
    
    <h4 class="subtitle" id="nameField"><span class="field">Name: </span><span class="contents">{{user.name}}</span></h4>
    <h4 class="subtitle" id="idField"><span class="field">ID: </span><span class="contents">{{user.id}}</span></h4>
    <h4 class="subtitle address" id="addressField"><span class="field">Street Address: <br></span><span class="contents">{{user.address}}</span></h4>
    <h4 class="subtitle" id="email1Field" *ngIf="user.email1"><span class="field">Email Address 1: </span><span class="contents">{{user.email1}}</span></h4>
    <h4 class="subtitle" id="email2Field" *ngIf="user.email2"><span class="field">Email Address 2: </span><span class="contents">{{user.email2}}</span></h4>
    <h4 class="subtitle" id="phoneField"><span class="field">Phone: </span><span class="contents">{{user.phone}}</span></h4>

</div>
<div>
    <h1 class="title">Account Activity</h1>
</div>

<h4 id="currentBalance" class="current_balance"> <span class="subtitle">Current balance: </span><span
        class="balance">{{currentBalance}}</span></h4>

<div class="portal_select_pay" *ngIf="isNested != true" id="buttonGroup">
   
    <input type="button" name="paypal" value="Pay with Card via PayPal" id="payPalButton" class="btn"
        (click)="openPopup('paypal')" />
</div>

<!-- Charge Type, Reference Number, Overpayment Date, Overpayment Recovery Amount, Gross Amount, Document Date, Due Date -->
<div class="table_container">
    <table class="aavp_table" style="overflow-y: hidden; overflow-x: auto;">
        <tr class="aavp_table_header">
            <th>Charge Type</th>
            <th>Reference Number</th>
            <th>Paycheck Reference Date</th>
            <th *ngIf="hasRecoveryAmount">Overpayment Recovery Amount</th>
            <th>{{amountHeader}}</th>
            <th>Activity Date</th>
            <th>Due Date</th>
        </tr>
        <tr class="aavp_table_row"  *ngFor="let charge of charges">
            <td>{{charge.chargeType}}</td>
            <td>{{(charge.referenceNumber) || "N/A"}}</td>
            <td>{{(charge.overpaymentDate | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</td>
            <ng-container *ngIf="hasRecoveryAmount">
                <td>{{ isOverpaymentValid(charge.overpaymentDate) ? charge.recoveryAmount : 'N/A' }}</td>
            </ng-container>
            <td>{{charge.grossAmount}}</td>
            <td>{{(charge.documentDate | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</td>
            <td>{{(charge.paymentDue | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</td>
        </tr>
    </table>
</div>
<!-- Trigger build -->
export class UserState {
    authenticated: boolean;
    name: string;
    details:{
      remoteAddress: string,
      sessionId: string,
      tokenType: string;
      tokenValue: string;
    }
    userAuthentication: {
      authenticated: boolean,
      details: {
        name: string,
        locale: string, //User ID
        email: string
      }
      name: string
    }
  }
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { UserService } from './user.service';


@Injectable()
export class IneligibleGaurd implements CanActivate {

    activate: boolean

    constructor(private http: HttpClient, private userService: UserService, private router: Router) {
        // this.cached = new Observable<Customer>
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.activate || this.activate === undefined) {
            return true
        } else {
            return false
        }
    }

}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



import { UserState } from './user-state';

@Injectable()
export class AuthService {

  userState: UserState;

  constructor(private http: HttpClient) { }

  
}

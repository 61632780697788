<div class="ui-fluid">
    <div style="width: 100%">
      <a [routerLink]="['/']"><img class="app-logo" alt="/" src="assets/img/aa-logo-web.png" /></a>
      <a style="float: right;" href="logoutURL"> Log Out</a>
      <a style="float: right;" [routerLink]="['/']"><img class="user-icon" alt="/" src="assets/img/user-icon.png" /></a>
      <a style="float: right;" [routerLink]="['/']"> {{name}}</a>
    </div>
  
    <div>
      <!-- <p-toolbar>
        <div class="ui-toolbar-group-left">
          <p-tabMenu [model]="items"></p-tabMenu>
        </div>
        <div class="ui-toolbar-group-right">
          <version-info></version-info>
        </div>
      </p-toolbar> -->
    </div>
  
  </div>
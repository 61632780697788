import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-success-dialog',
  templateUrl: './payment-success-dialog.component.html',
  styleUrls: ['./payment-success-dialog.component.css'],
})
export class PaymentSuccessDialogComponent {

  // message: string = 'Your payment was successful! Clearing will take 24 hours to reflect on the portal.';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PaymentSuccessDialogComponent>) {}

  // Method to refresh the page
  refreshPage(): void {
    window.location.reload(); // method to reload page
  }
}

import { Component } from '@angular/core';
import { VERSION } from '../../environments_bkup/version';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent {
  version = VERSION;
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ineligible',
  templateUrl: './ineligible.component.html',
  styleUrls: ['./ineligible.component.scss']
})
export class IneligibleComponent{

  
  constructor() {
  }

  // ngOnInit() {
   
  // }
  logout(){

  }

}
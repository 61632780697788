<h1 class="title">Mass Upload Success List</h1>

<div class="section" style="margin-left: 20px;">
    <mat-table [dataSource]="dataSourceSuccess" class="mat-elevation-z8">
        <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue; font-weight: bold;"> Invoice Number </th>
            <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 20px; border: none;"> {{element.invoiceNumber}} </td>

        </ng-container>
        <ng-container matColumnDef="salesOrderNumber">
            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue;font-weight: bold;"> Sales Order Number </th>
            <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 0; border: none;"> {{element.salesOrderNumber}} </td>

        </ng-container>
        <ng-container matColumnDef="chargeCategory">
            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue;font-weight: bold;"> Charge Category </th>
            <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 0; border: none;"> {{element.chargeCategory}} </td>


        </ng-container>
        <ng-container matColumnDef="chargeCode">

            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue;font-weight: bold;"> Charge Code </th>
            <td mat-cell *matCellDef="let element" style="width:150px; padding: 20px 0; border: none;"> {{element.chargeCode}} </td>


        </ng-container>
        <ng-container matColumnDef="totalNetAmount">
            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue;font-weight: bold;"> Total Net Amount </th>
            <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 0; border: none;"> {{element.totalNetAmount | number:'1.2-2'}} </td>


        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginatorS (page)="retrieveInvoiceSuccess($event)" [pageSize]="pageSizeS" [pageIndex]="pageIndexS" [length]="totalSizeS" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>

<h1 class="title">Mass Upload Failure List</h1>

<div class="section" style="margin-left: 20px;">
    <mat-table [dataSource]="dataSourceFailure" class="mat-elevation-z8">
        <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue; font-weight: bold;"> Invoice Number </th>
            <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 20px; border: none;"> {{element.invoiceNumber}} </td>

        </ng-container>

        <ng-container matColumnDef="chargeCategory">
            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue;font-weight: bold;"> Charge Category </th>
            <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 0; border: none;"> {{element.chargeCategory}} </td>


        </ng-container>
        <ng-container matColumnDef="chargeCode">

            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue;font-weight: bold;"> Charge Code </th>
            <td mat-cell *matCellDef="let element" style="width:150px; padding: 20px 0; border: none;"> {{element.chargeCode}} </td>


        </ng-container>
        <ng-container matColumnDef="totalNetAmount">
            <th mat-header-cell *matHeaderCellDef style="width:150px;border-bottom: 1px solid blue;font-weight: bold;"> Total Net Amount </th>
            <td mat-cell *matCellDef="let element" style="width:150px;padding: 20px 0; border: none;"> {{element.totalNetAmount}} </td>


        </ng-container>
        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef style="width:250px;border-bottom: 1px solid blue;font-weight: bold;"> Errors </th>
            <td mat-cell *matCellDef="let element" style="width:250px;padding: 20px 0; border: none;"> {{element.errors}} </td>


        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns1;"></mat-row>
    </mat-table>
    <mat-paginator #paginatorF (page)="retrieveInvoiceFailure($event)" [pageSize]="pageSizeF" [pageIndex]="pageIndexF" [length]="totalSizeF" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
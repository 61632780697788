import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, EMPTY, of, } from 'rxjs';
import { Admin } from '../model/Admin';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { StatusResponse } from '../model/StatusResponse';
import { UploadResponse } from '../model/upload-response';
import { InvoiceResultsResponse } from '../model/resultscontent';
import { UploadDetail } from '../model/UploadDetail';
import { UserService } from './user.service';
import { AppComponent } from '../app.component';
import { LoggedInUser } from '../model/LoggedInUser';
import { ConfigService } from './config.service';


@Injectable()
export class TBSService implements CanActivate {
    @Input() aavp_id: string;
    tbsUser: Observable<Admin>
    activate: boolean;
   // user = JSON.parse(localStorage.getItem('user')) as LoggedInUser;


    constructor(private http: HttpClient, private router: Router, private configService : ConfigService) { 
    }
 

    //ADD TBS SPECIFIC FUNCTIONS HERE
    public checkRefreshStatus(): Observable<StatusResponse> {
        return this.http.get<StatusResponse>(`${this.configService.config.aavpGatewayUrl}masterData/refreshStatus`)
    }

    public refreshCostCenter(): Observable<string> {
        return this.http.get<string>(`${this.configService.config.aavpGatewayUrl}masterData/refreshCostCenter`, {responseType: 'text' as 'json'})
    }

    public refreshMaterial(): Observable<string>{
        return this.http.get<string>(`${this.configService.config.aavpGatewayUrl}masterData/refreshMaterial`, {responseType: 'text' as 'json'})
    }

    public refreshFSG():Observable<string>{
        return this.http.get<string>(`${this.configService.config.aavpGatewayUrl}masterData/refreshFSG`, {responseType: 'text' as 'json'})
    }

    // public uploadXML(xml: string, fileName: string): Observable<String> {
    //         console.log('in TBS Service');
    //     const headers = new HttpHeaders().set('Content-Type', 'application/xml; charset=utf-8');
    //     return this.http.post<String>(`${environment.empServiceUrl}tbs/upload/xml/${fileName}`, xml, {headers: headers, responseType: 'text' as 'json'})
    // }

    public uploadXML(xml: string, fileName: string): Observable<UploadDetail> {
        console.log('in TBS Service');
    const headers = new HttpHeaders().set('Content-Type', 'application/xml; charset=utf-8').set('aavp-id', '795963');
    return this.http.post<UploadDetail>(`${this.configService.config.aavpGatewayUrl}upload/xml?${fileName}`, xml, {headers: headers})
}

    public retrieveUploadDetails(pageSize: number, pageIndex: number):Observable<UploadResponse>{
        return this.http.get<UploadResponse>(`${this.configService.config.aavpGatewayUrl}upload/uploaddetails?page=${pageIndex}&size=${pageSize}&sort=timestamp,desc`)
    }

    public uploadStatus():Observable<UploadDetail[]>{
        return this.http.get<UploadDetail[]>(`${this.configService.config.aavpGatewayUrl}upload/status`)
    }
    
    public retrieveInvoiceResultsSuccess(id: string, pageSize: number, pageIndex: number):Observable<InvoiceResultsResponse>{
        console.log('in Retrieve Invoice Results Service');
        return this.http.get<InvoiceResultsResponse>(`${this.configService.config.aavpGatewayUrl}upload/uploaddetailssuccess?${id}?page=${pageIndex}&size=${pageSize}`)
    }

    public retrieveInvoiceResultsFailure(id: string, pageSize: number, pageIndex: number):Observable<InvoiceResultsResponse>{
        console.log('in Retrieve Invoice Results Service');
        return this.http.get<InvoiceResultsResponse>(`${this.configService.config.aavpGatewayUrl}upload/uploaddetailsfailure?${id}?page=${pageIndex}&size=${pageSize}`)
    }

    public test(): Observable<String> {
        return this.http.get<String>(`${this.configService.config.aavpGatewayUrl}upload/test`)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (this.activate) {
            return true
        } else {
            return this.router.createUrlTree(['/']);
        }
    }
}